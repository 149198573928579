import React from 'react';
import { Message } from 'semantic-ui-react';
import Post from '../components/post';
import EvenFinancialWidget from '../components/EvenFinancialWidget';
import LazyLoadImage from '../components/LazyLoadImage';
import Formula from '../components/Formula';
import FormulaItem from '../components/FormulaItem';


const UsDebtPresidentsPage = () => {
    const article = {
        id: '5a94a73c-de20-5445-a404-e431ca3578e4',
        title: 'The $28 Trillion U.S. Debt: Which President Contributed the Most',
        slug: '/us-debt-presidents/',
        date: '2018-05-22T21:05:36.000Z',
        modified: '2021-11-01T18:41:08.000Z',
        excerpt: 'Discover which president contributed the most to United States&#8217;s $22 trillion in public debt. It&#8217;s not always a clear cut answer, as there are different ways to measure it. Here&#8217;s how five recent presidents compare.',
        featured_image: {
            source_url: '/media/the-28-trillion-us-debt-which-president-contributed-the-most-0.png',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 12,
        content: (
            <>
                <Message icon floating alert>
                    <p className="text-align--center">As of April 2021, the total U.S. debt is</p>
                    <h3 className="text-align--center" style={{ fontSize: '1.5em', marginTop: '1em' }}>$28,174,714,484,167</h3>
                    <p className="text-align--center">And it&rsquo;s rising fast.</p>
                </Message>
                <h2 id="trillions-with-a-t">Trillions With A &quot;T&quot;</h2>
                <p>
                    The U.S. national debt is over 28&nbsp;
                    <strong>TRILLION</strong>
                    {' '}
                    dollars. That&rsquo;s trillions — with a &quot;T&quot;. As in 28,174 billion dollars. It&rsquo;s terrifying.
                </p>
                <p>It&rsquo;s way more than the combined cost of World War II, the Korean War, the Vietnam War, and NASA&rsquo;s entire space program since it started.</p>
                <h2 id="which-presidents-are-to-blame">Which Presidents Are To Blame?</h2>
                <p>Of course, each political party points to the other. The fact is there&rsquo;s no easy answer, because there are different ways to measure the situation.</p>
                <p>
                    The most popular method is to simply compare the
                    {' '}
                    <a href="https://www.debtconsolidation.com/">debt</a>
                    {' '}
                    level from when a president enters the White House to the debt level when he leaves.
                </p>
                <p>
                    After all, it&rsquo;s your debt, too.
                    <em> You&rsquo;re paying for this debt when you pay taxes.</em>
                </p>
                <p><LazyLoadImage src="/media/national-debt-increases.png" alt="U.S. Public Debt Since 1990" className="ui centered image" /></p>
                <h2 id="were-all-in-debt-together">We&rsquo;re All In Debt Together</h2>
                {' '}
                <Message icon floating alert>
                    <p className="text-align--center">
                        The American debt per citizen is over
                        {' '}
                        <strong>$85,332</strong>
                    </p>
                </Message>
                <p>
                    We&rsquo;re constantly under assault by debt. We&rsquo;re crushed under the weight of
                    {' '}
                    <span style={{ backgroundColor: '#ffffff' }}><a style={{ backgroundColor: '#ffffff' }} href="/credit-card-debt/">credit card debt</a></span>
                    . We&rsquo;re up to our eyeballs in
                    {' '}
                    <span style={{ backgroundColor: '#ffffff' }}>medical debt</span>
                    . We&rsquo;re choosing between paying rent and
                    {' '}
                    <span style={{ backgroundColor: '#ffffff' }}><a style={{ backgroundColor: '#ffffff' }} href="https://www.debtconsolidation.com/student-loans/">student loans</a></span>
                    {' '}
                    because it&rsquo;s possible to be making minimum wage with an expensive college degree.
                </p>
                <p>So what&rsquo;s another 85K, right? A lot, actually.</p>
                <p>If our country doesn&rsquo;t correct its debt spiral, it risks defaulting on its obligations. That would diminish its power around the world.</p>
                <p>It could also raise interest rates, reduce spending on government programs, and weaken entitlements like Social Security.</p>
                <p>Since the US national debt now exceeds the gross domestic product (GDP), our economy seems to be headed off a cliff. Yet Congress keeps raising the debt ceiling, allowing the federal government to borrow more and more without paying it back. As citizens, we need to be better informed.</p>
                <p>
                    Does your personal debt feel as dire as our national debt?
                    {' '}
                    <span style={{ backgroundColor: '#ffffff' }}><a style={{ backgroundColor: '#ffffff' }} href="/loans/#get-started">We can help.</a></span>
                </p>
                <p style={{
                    fontSize: '1.71428571rem', lineHeight: '1.28571429em', fontWeight: 700, marginTop: '31.5917px', marginBottom: '5px', textAlign: 'center',
                }}
                >
                    See If You Qualify for a Personal&nbsp;Loan up&nbsp;to&nbsp;$250k
                </p>
                <EvenFinancialWidget url="https://embed.hifiona.com/ui/multi-product/index.html?partner=debtconsolidationembed&amp;access_token=628217c7-f4c2-4006-a2b1-3031b4c21272_8362ee6d-9497-4440-aaf2-8c6e5685f90b&amp;company_uuid=65d1a0bd-1cab-43c4-b755-2a6bff913a03&amp;productType=loan&amp;pw_fiona=true&amp;available_products=loan" slug="us-debt-presidents" />
                <h2 id="why-we-wrote-this">Why We Wrote This</h2>
                <p>We wanted to understand how individual presidents have affected the nation&rsquo;s bottom line. We explored the presidents who&rsquo;ve held office since 1981.</p>
                <ol className="list">
                    <li><a href="#president-section-ronald-reagan">Ronald Reagan</a></li>
                    <li><a href="#president-section-george-bush-senior">George H.W. Bush</a></li>
                    <li><a href="#president-section-bill-clinton">Bill Clinton</a></li>
                    <li><a href="#president-section-george-bush-junior">George W. Bush</a></li>
                    <li><a href="#president-section-barack-obama">Barack Obama</a></li>
                    <li><a href="#president-section-donald-trump">Donald Trump</a></li>
                </ol>
                <h2 id="measuring-presidential-debt">Measuring Presidential Debt</h2>
                <p>To understand a president&rsquo;s contribution to the national debt, we first need to learn how it&rsquo;s measured. The easiest way is to compare his first and last days on the job.</p>
                <p>Daily debt numbers aren&rsquo;t available before 1993, we haven&rsquo;t used that measurement to compare the presidents since 1981. For instance, on the day that Barack Obama was sworn in—January 20, 2009—the debt was $10.626 trillion. When he left the White House on January 20, 2017, it was $19.947 trillion.</p>
                <h3>The Math That Seems Simple</h3>
                <p>Obama added $9 trillion in debt during his term. George W. Bush added $4.9 trillion. Bill Clinton added $1.5 trillion.</p>
                <p>Debt statistics like those are ready-made talking points for cable news shows and stump speeches. But they can also be misleading.</p>
                <p>Why? Because during a president&rsquo;s first year in office, he doesn&rsquo;t control the debt. He inherits his predecessor&rsquo;s budget.</p>
                <p>The budget for Obama&rsquo;s first year in office was set by George W. Bush and during the 2009 fiscal year, it created a $1.16 trillion deficit. Bush had left the White House but it was the next guy who picked up the tab.</p>
                <p>Comparing the numbers is simple. Knowing who the numbers belong to, and more importantly who to blame, is the useful information.</p>
                <h2 id="debt-to-gdp-ratio-a-very-helpful-number">Debt-to-GDP Ratio: A Very Helpful Number</h2>
                <p>GDP (Gross Domestic Product) is the total output of all American businesses. It&rsquo;s basically how much money a country makes.</p>
                <p>
                    <a href="https://www.debtconsolidation.com/national-debt-effects/">Comparing the national debt</a>
                    {' '}
                    to its GDP under a given president is a classic way to gauge a country&rsquo;s economic health during that time.
                </p>
                {' '}
                <Formula heading="Calculating Debt-to-GDP ">
                    <FormulaItem>National Debt</FormulaItem>
                    <FormulaItem operator="/">GDP</FormulaItem>
                    <FormulaItem operator="=">Debt-to-GDP Ratio* </FormulaItem>
                </Formula>
                <p className="text-align--center"><em>*The ratio is usually written as a percent</em></p>
                {' '}
                <Message icon floating notice>A high Debt-to-GDP ratio is considered bad. A low ratio is considered good.</Message>
                <p>&nbsp;</p>
                <p><LazyLoadImage src="/media/growth-forecast-of-u-s-national-debt-in-relation-to-gdp-2.png" alt="Growth and Forecast of U.S. National Debt in Relation to GDP" className="ui centered image" /></p>
                <p>Money&rsquo;s never quite that simple though. Debt and GDP go hand in hand. &quot;You have to spend money to make money.&quot;</p>
                <p>The world&rsquo;s top economies are among its highest debtors. Japan, Germany and the U.S. all have plenty of debt. They also genrate lots of cash to go with it. A country with a high-powered economy can keep more debt than smaller countries while maintaining it&rsquo;s credit rating.</p>
                <h3>What&rsquo;s a normal Debt-to-GDP for the U.S.?</h3>
                <p>For most of US history, the Debt-to-GDP ratio has been under 50%. But that doesn&rsquo;t tell the whole story.</p>
                <p>U.S. debt at the end of World War II totaled $241.86 billion ($2.9 trillion with inflation). That&rsquo;s far less debt than the 29 trillion the US has today.</p>
                <p>
                    Its debt-to-GDP ratio at that time however was also at an all-time high of 113%.
                    <strong>&nbsp;</strong>
                    In contrast, due to the COVID-19 pandemic and the CARES Act, the ratrio has increased to
                    {' '}
                    <strong>129% as of 2020</strong>
                    .
                </p>
                {' '}
                <Message icon floating alert>
                    {' '}
                    Is your personal debt-to-GDP ratio out of control? You can
                    {' '}
                    <span><a href="/loans/#get-started">start fixing it today</a></span>
                </Message>

                <h3>Confidence Isn&rsquo;t Always About The Numbers</h3>
                <p className="text-align--center">
                    Ask yourself this:
                    {' '}
                    <em>Do you feel confident that the economy is healthier in 2021 than it was during the Great Depression of the 1930&rsquo;s?</em>
                </p>
                <p>
                    You almost certainly do because the economy
                    {' '}
                    <em>is</em>
                    {' '}
                    healthier today. But when unemployment had reached 25% and middle class Americans were relying on soup kitchens to stay alive, the
                    {' '}
                    <a href="https://www.theatlantic.com/business/archive/2012/11/the-long-story-of-us-debt-from-1790-to-2011-in-1-little-chart/265185/" target="_blank" rel="noopener noreferrer">Debt-to-GDP ratio was only 44%</a>
                    .
                    <br />
                    {' '}
                    <br />
                    Today it&rsquo;s
                    {' '}
                    <a href="https://www.thebalance.com/national-debt-by-year-compared-to-gdp-and-major-events-3306287" target="_blank" rel="noopener noreferrer">129%</a>
                    .
                </p>
                <p>The president of the United States makes tough financial decisions every day. Each move invites half the country to praise and the other to criticize.</p>
                <p>If you want to feel confident about which side of the argument to join, you need context. Decisions aren&rsquo;t made in a vacuum and neither should your opinions.</p>
                <p>Here&rsquo;s our break down the last 6 POTUS. Judge or forgive away.</p>
                <h3 className="text-align--center"><LazyLoadImage src="/media/white-house-icon-blue.png" alt="white house logo" /></h3>
                <div
                    id="president-section-ronald-reagan"
                    className="president-headshot"
                    style={{
                        margin: '4em 0 4em 0', background: '#bbe6f533', padding: '4em 0', border: '5px solid #1a4dc5',
                    }}
                >
                    <p className="text-align--center"><LazyLoadImage style={{ borderRadius: '50%', maxWidth: '300px', border: '4px solid #dc2b2b' }} src="/media/president-reagan.jpg" alt="Ronald Reagan headshot" /></p>
                    <h2 className="text-align--center" id="ronald-reagan">Ronald Reagan</h2>
                    <p className="text-align--center">40th President of the United States</p>
                    <p className="text-align--center"><em>1981 – 1989</em></p>
                </div>
                <h3>Budgets</h3>
                <ul className="list">
                    <li>1981: $678,241 million</li>
                    <li>1989: $1.143 trillion</li>
                    <li>$1.5 trillion deficit</li>
                    <li>Increased defense budget and expanded Medicare</li>
                    <li>Highest deficits since wartime</li>
                </ul>
                <h3>Reduced Taxes</h3>
                <ul className="list">
                    <li>Reaganomics&rsquo; failure to stimulate economy left goverment underfunded</li>
                    <li>Slashed top income bracket rate from 70% – 28%</li>
                </ul>
                <h3>Recession</h3>
                <ul className="list">
                    <li>Unemployment rate was 6% – 10%</li>
                    <li>High interest rates triggerd down turn</li>
                </ul>
                <h3>Natonal Debt</h3>
                <ul className="list"><li>Increased debt by $1.859 trillion</li></ul>
                <h3>Debt-to-GDP ratio</h3>
                <ul className="list"><li>Increased from 31% to 51%</li></ul>
                <h3 className="text-align--center"><LazyLoadImage src="/media/white-house-icon-blue.png" alt="white house logo" /></h3>
                <div
                    id="president-section-george-bush-senior"
                    className="president-headshot"
                    style={{
                        margin: '4em 0 4em 0', background: '#bbe6f533', padding: '4em 0', border: '5px solid #1a4dc5',
                    }}
                >
                    <p className="text-align--center"><LazyLoadImage style={{ borderRadius: '50%', maxWidth: '300px', border: '4px solid #dc2b2b' }} src="/media/president-bush-senior.jpg" alt="George bush headshot" /></p>
                    <h2 className="text-align--center" id="george-hw-bush">George H.W. Bush</h2>
                    <p className="text-align--center">41st President of the United States</p>
                    <p className="text-align--center"><em>1989 – 1993</em></p>
                </div>
                <h3>Budgets</h3>
                <ul className="list">
                    <li>1989 – $1.143 trillion</li>
                    <li>1993 – $1.409 trillion</li>
                    <li>$1.188 trillion in deficits</li>
                </ul>
                <h3>War Spending</h3>
                <ul className="list">
                    <li>Invaded Iraq</li>
                    <li>Gulf War led to huge increase in military spending</li>
                </ul>
                <p className="text-align--center"><LazyLoadImage className="ui centered image" src="/media/u-s-military-spending-3.png" alt="U.S. Military Spending" /></p>
                <h3>Recession</h3>
                <ul className="list">
                    <li>
                        $125 billion for
                        {' '}
                        <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=us-debt-presidents&amp;sub2=savings" rel="noopener noreferrer" target="_blank">Savings</a>
                        {' '}
                        and Loan Crisis
                    </li>
                </ul>
                <h3>Natonal Debt Increase</h3>
                <ul className="list">
                    <li>Defecits increased debt by $1.554 trillion</li>
                    <li>Increased debt to $4.411 trillion by 1993</li>
                </ul>
                <h3>Debt-to-GDP ratio</h3>
                <ul className="list"><li>Increased from 51% to 64%</li></ul>
                <h3 className="text-align--center"><LazyLoadImage src="/media/white-house-icon-blue.png" alt="white house logo" /></h3>
                <div
                    id="president-section-bill-clinton"
                    className="president-headshot"
                    style={{
                        margin: '4em 0 4em 0', background: '#bbe6f533', padding: '4em 0', border: '5px solid #1a4dc5',
                    }}
                >
                    <p className="text-align--center"><LazyLoadImage style={{ borderRadius: '50%', maxWidth: '300px', border: '4px solid #dc2b2b' }} src="/media/president-clinton.jpg" alt="Bill Clinton headshot" /></p>
                    <h2 className="text-align--center" id="bill-clinton">Bill Clinton</h2>
                    <p className="text-align--center">42th President of the United States</p>
                    <p className="text-align--center"><em>1993 – 2001</em></p>
                </div>
                <h3>Raised Taxes And Cut Spending</h3>
                <ul className="list">
                    <li>Only president since 1980 to leave office in 2001 with a balanced budget</li>
                    <li>Decreased military spending following the Cold War</li>
                    <li>New taxes fueled by the Dot Com Bubble increased revenue</li>
                </ul>
                <h3>Budget</h3>
                <ul className="list">
                    <li>1993 – $1.409 trillion</li>
                    <li>2001 – $1.862 trillion</li>
                    <li>Instituted strict fiscal policies</li>
                    <li>Reduced number of U.S. troops stationed around the world</li>
                </ul>
                <h3>The (Social Security) Surplus</h3>
                <ul className="list">
                    <li>Left office with $36 billion surplus</li>
                    <li>Critics feel surplus owed to Social Security tax on payrolls</li>
                </ul>
                <h3>GDP And Debt</h3>
                <ul className="list">
                    <li>Increased debt from $4.4 trillion to $5.8 trillion</li>
                    <li>Though the debt grew by 32%, it was a lower percentage of GDP</li>
                    <li>The debt-to-GDP ratio decreased from 64% to 55%</li>
                </ul>
                <h3>A Booming Economy</h3>
                <ul className="list"><li>GDP skyrockets to over $10 trillion</li></ul>
                <h3>Taxes Raised</h3>
                <ul className="list"><li>Top tax bracket rate increased to 39.6%</li></ul>
                <h3 className="text-align--center"><LazyLoadImage src="/media/white-house-icon-blue.png" alt="white house logo" /></h3>
                <div
                    id="president-section-george-bush-junior"
                    className="president-headshot"
                    style={{
                        margin: '4em 0 4em 0', background: '#bbe6f533', padding: '4em 0', border: '5px solid #1a4dc5',
                    }}
                >
                    <p className="text-align--center"><LazyLoadImage style={{ borderRadius: '50%', maxWidth: '300px', border: '4px solid #dc2b2b' }} src="/media/president-bush-junior.jpg" alt="George w bush headshot" /></p>
                    <h2 className="text-align--center" id="george-w-bush">George W. Bush</h2>
                    <p className="text-align--center">43th President of the United States</p>
                    <p className="text-align--center"><em>2001 – 2009</em></p>
                </div>
                <h3>9/11 – New Levels of Goverment Spending</h3>
                <p>The 9/11 terrorist attacks dramatically reshaped the U.S. economy.</p>
                <ul className="list">
                    <li>Military spending surged to $600 billion/year</li>
                    <li>Afghanistan and Iraq wars primary cost</li>
                </ul>
                <h3>Budgets</h3>
                <ul className="list">
                    <li>2001 – $1.862 trillion</li>
                    <li>2009 – $3.157 trillion</li>
                    <li>TARP adds $700 billion after housing crisis</li>
                    <li>Deficits totaled $3.418 trillion</li>
                </ul>
                <h3>Reduced Taxes</h3>
                <ul className="list">
                    <li>Cut taxes in 2001 and 2003</li>
                    <li>Top tax bracket rate lowered to 35%</li>
                    <li>Decreased government revenue</li>
                </ul>
                <h3>Recession</h3>
                <ul className="list">
                    <li>Dot-Com Bubble burst</li>
                    <li>Subprime mortgage crisis</li>
                    <li>Widespread foreclosures increases avg household debt</li>
                </ul>
                <h3>Natonal Debt Increase</h3>
                <ul className="list">
                    <li>Public debt increased by $6.103 trillion</li>
                    <li>Billions added to debt by Medicare Part D prescription drug program</li>
                    <li>Slowed cost of Medicare</li>
                </ul>
                <h3>Debt-to-GDP ratio</h3>
                <ul className="list"><li>Debt-to-GDP rose from 55% to 82%</li></ul>
                <h3 className="text-align--center"><LazyLoadImage src="/media/white-house-icon-blue.png" alt="white house logo" /></h3>
                <div
                    id="president-section-barack-obama"
                    className="president-headshot"
                    style={{
                        margin: '4em 0 4em 0', background: '#bbe6f533', padding: ' 4em 0', border: '5px solid #1a4dc5',
                    }}
                >
                    <p className="text-align--center"><LazyLoadImage style={{ borderRadius: '50%', maxWidth: '300px', border: '4px solid #dc2b2b' }} src="/media/president-obama.jpg" alt="barack obama headshot" /></p>
                    <h2 className="text-align--center" id="barack-obama">Barack Obama</h2>
                    <p className="text-align--center">44th President of the United States</p>
                    <p className="text-align--center"><em>2009 – 2017</em></p>
                </div>
                <h3>Budgets</h3>
                <ul className="list">
                    <li>2009 – $3.157 trillion</li>
                    <li>2017 – $3.981 trillion</li>
                    <li>Military spending increased to approximately $685 billion per year to fund two inherited wars</li>
                    <li>Deficits totaled $7.939 trillion</li>
                    <li>Entitlement programs like Medicare and Social Security became more expensive with the againg population</li>
                </ul>
                <h3>Reduced Taxes</h3>
                <ul className="list">
                    <li>Obama extended the Bush tax cuts to stimulate the economy and extended them through 2012. This added $858 billion to the debt</li>
                    <li>Americans&rsquo; pay decreased during the recession causing tax revenue to fall</li>
                </ul>
                <h3>The Great Recession (2008)</h3>
                <ul className="list">
                    <li>Upon taking office, the stock market had cratered and businesses were shedding jobs</li>
                    <li>The American Recovery and Reinvestment Act was was passed as an economic stimulus package costing $787 billion</li>
                </ul>
                <h3>National Debt Increase</h3>
                <ul className="list">
                    <li>Obama added $8.335 trillion to the public debt – more than any modern president</li>
                    <li>In 2001, the public debt was 55% of GDP. By 2009, it had increased to 82%</li>
                    <li>The Medicare Part D Bill was passed reduce the cost of prescription drugs. This added billions to the debt but slowed the cost of Medicare</li>
                </ul>
                <h3>Debt-to-GDP ratio</h3>
                <ul className="list"><li>The debt-to-GDP ratio grew enormously from 82% to 104%</li></ul>
                <h3>Obamacare</h3>
                <ul className="list"><li>The bipartisan Congressional Budget Office estimates that Obamacare (the Affordable Care Act) would reduce deficits by $154 billion over its first decade</li></ul>
                <p><LazyLoadImage className="ui centered image" src="/media/forecast-of-coverage-provisions-contained-in-the-affordable-care-act-4.png" alt="Forecast of Coverage Provisions Contained in the Affordable Care Act" /></p>
                <h3 className="text-align--center"><LazyLoadImage src="/media/white-house-icon-blue.png" alt="white house logo" /></h3>
                <div
                    id="president-section-donald-trump"
                    className="president-headshot"
                    style={{
                        margin: '4em 0 4em 0', background: '#bbe6f533', padding: '4em 0', border: '5px solid #1a4dc5',
                    }}
                >
                    <p className="text-align--center"><LazyLoadImage style={{ borderRadius: '50%', maxWidth: '300px', border: '4px solid #dc2b2b' }} src="/media/president-trump.png" alt="Donald Trump headshot" /></p>
                    <h2 className="text-align--center" id="donald-trump">Donald Trump</h2>
                    <p className="text-align--center">45th President of the United States</p>
                    <p className="text-align--center"><em>2017 – 2021</em></p>
                </div>
                <h3>Budgets</h3>
                <ul className="list">
                    <li>2017 – $3.981 trillion</li>
                    <li>2020 – $6.550 trillion</li>
                    <li>Increased annual spending by over 64% from 2017 to 2020, heavily impacted by the COVID-19 pandemic and the resulting CARES Act.</li>
                    <li>Deficits totaled $5.557 trillion</li>
                </ul>
                <h3>Reduced Taxes</h3>
                <ul className="list">
                    <li>Top income bracket tax rate reduced from 39.6% to 37%</li>
                    <li>Nearly doubled the standard deduction to a new 2020 amount of $12,400</li>
                    <li>Capped State and Local Tax (SALT) deductions to $10,000 annually ($5,000 for taxpayers filing seperately)</li>
                    <li>Reduced the cap on Mortgage Interest Deductions from $1m in deductions to only $750,000</li>
                    <li>Doubled the Child Tax Credit from $1,000 per child under 17 to $2,000 per child</li>
                    <li>Doubled the Estate Tax deduction from a limit of $5.49 million to $11.18 million allowing wealthy families to transfer more money to their heirs, tax-free</li>
                    <li>Removed the individual mandate for health insurance previously put in place by Obama and the Afforadble Care Act</li>
                    <li>Deductions are no longer allowed for alimony</li>
                    <li>401(k) base contribution limits increased from $19,000 to $19,500</li>
                    <li>HSA contribution limits also inceased. The limit for self-coverage increased from $3,500 to $3,550, while family coverage limits increased from $7,000 to $7,100</li>
                </ul>
                <h3>COVID-19</h3>
                <ul className="list">
                    <li>The FY2020 federal budget deficit totaled $3.1 trillion,&nbsp;more than triple its FY2019 value, and the Congressional Budget Office projects the bills enacted in to increase deficits by $2.6 trillion</li>
                    <li>Additional relief and stimulus was enacted in 2021 which CBO and JCT estimate would increase deficits by $1 trillion over the 2021-2031 period, $868 billion of which comes from COVID-19 related provisions. This was followed by the American Rescue Plan Act of 2021, which is estimated to increase deficits by nearly $2 trillion over the 2021-2031 period.</li>
                </ul>
                <h3>National Debt Increase</h3>
                <ul className="list"><li>Trump added $6.2 trillion to national debt in his four year term</li></ul>
                <h3>Debt-to-GDP ratio</h3>
                <ul className="list"><li>The debt-to-GDP ratio grew enormously from 104% to 129%</li></ul>
                <p>&nbsp;</p>
                <h3 className="text-align--center"><LazyLoadImage src="/media/white-house-icon-blue.png" alt="white house logo" /></h3>
                <div
                    id="president-section-you"
                    className="president-headshot"
                    style={{
                        margin: '4em 0 4em 0', background: '#bbe6f533', padding: '4em 0', border: '5px solid #1a4dc5',
                    }}
                >
                    <p className="text-align--center"><LazyLoadImage style={{ borderRadius: '50%', maxWidth: '300px', border: '4px solid #dc2b2b' }} src="/media/president-uncle-sam.jpg" alt="you" /></p>
                    <h2 className="text-align--center" id="you">You</h2>
                    <p className="text-align--center">The American Citizen</p>
                    <p className="text-align--center"><em>2021</em></p>
                </div>
                <p>Just like their government, many Americans are adding to their personal debt faster than they can pay it down. Household debt is at an all-time high. Does any of this look familiar to you?</p>
                <h3>Budget</h3>
                <ul className="list">
                    <li>Living paycheck to paycheck (78% of us do)</li>
                    <li>
                        According to a Career Builder
                        {' '}
                        <a href="https://press.careerbuilder.com/2017-08-24-Living-Paycheck-to-Paycheck-is-a-Way-of-Life-for-Majority-of-U-S-Workers-According-to-New-CareerBuilder-Survey" target="_blank" rel="noopener noreferrer">survey</a>
                        :
                        <ul>
                            {' '}
                            <li>
                                {' '}
                                <div className="wd_subtitle wd_language_left">   Nearly one in 10 workers making $100,000+ live paycheck to paycheck  </div>
                                {' '}
                            </li>
                            {' '}
                            <li>
                                {' '}
                                <div className="wd_subtitle wd_language_left">
                                    {' '}
                                    More than 1 in 4 workers do not set aside any
                                    {' '}
                                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=us-debt-presidents&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                                    {' '}
                                    each month
                                    {' '}
                                </div>
                                {' '}
                            </li>
                            {' '}
                            <li>
                                {' '}
                                <div className="wd_subtitle wd_language_left">   Nearly 3 in 4 workers say they are in debt – and more than half think they always will be  </div>
                                {' '}
                            </li>
                            {' '}
                            <li>
                                {' '}
                                <div className="wd_subtitle wd_language_left">   More than half of minimum wage workers say they have to work more than one job to make ends meet  </div>
                                {' '}
                            </li>
                            {' '}
                            <li>
                                {' '}
                                <div className="wd_subtitle wd_language_left">
                                    {' '}
                                    28% of workers making&nbsp;
                                    <span className="xn-money">$50k – $100k</span>
&nbsp;usually or always live paycheck to paycheck, and 70% are in debt
                                    {' '}
                                </div>
                                {' '}
                            </li>
                            {' '}
                        </ul>
                    </li>
                </ul>
                <h3>Debt</h3>
                <ul className="list">
                    <li>
                        <span style={{ backgroundColor: '#ffffff' }}>
                            <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=us-debt-presidents&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">Credit card</a>
                            {' '}
                            debt
                        </span>
                        {' '}
                        keeps growing
                    </li>
                    <li>Medical bills past due</li>
                    <li>
                        <span style={{ backgroundColor: '#ffffff' }}>
                            <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=us-debt-presidents&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">Student loan</a>
                            {' '}
                            payments
                        </span>
                        {' '}
                        consume a good chunk of monthly income
                    </li>
                    <li>
                        Over twelve milloin Americans each year leverage
                        {' '}
                        <span style={{ backgroundColor: '#ffffff' }}><a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=us-debt-presidents&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a></span>
                        {' '}
                        products to augment their financial situation
                    </li>
                </ul>
                <h3>Your personal Debt-to-Income ratio</h3>
                <ul className="list"><li>Your The debt-to-income ratio is more than 100%</li></ul>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default UsDebtPresidentsPage;
